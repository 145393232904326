import * as React from 'react';
import { useState } from 'react';
import Snap from './Snap';
import { isMobile } from 'react-device-detect';
import { useDeviceOrientation } from './hooks/useDeviceOrientation';
import DesktopFallback from './DesktopFallback';
import LandscapeFallback from './LandscapeFallback';
import { useSentry } from './third-party-services/useSentry';
import { ga_event } from './Analytics';
import * as Config from './Config';
import MobileIntro from './MobileIntro';
import MobileLoading from './MobileLoading';

function reportCampaign() {
  // getting the campaing parameter
  // Parse the query parameters from the URL
  const params = new URLSearchParams(window.location.search);

  const campaign = params.get('utm_campaign');

  if (campaign) {

    if (process.env.REACT_APP_ENV === 'development')
    {
      console.log("debugging");
    }
    else
    {
      ga_event(`campaign-${campaign}`, {});
    }
    
  }
}

const App: React.FC = () => {
  useSentry();

  const [permissionGranted, setPermissionGranted] = useState(false);
  const [snapLoaded, setSnapLoaded] = useState(false);
  const { isPortrait } = useDeviceOrientation();
  const [isLoading, setIsLoading] = useState(false);


  React.useEffect(() => {
    reportCampaign();

  }, []);

  const isAndroidDevice = (): boolean => {
    console.log(navigator.userAgent);
    return /Android/i.test(navigator.userAgent);
  };

const requestPermission = () => {

  // loading page 
  setIsLoading(true);

  console.log("requesting permissions");

  if (process.env.REACT_APP_ENV === 'development')
  {
    setPermissionGranted(true);
  }
  else
  {
   // request access to orientation and motion (only iOS)
   if(!isAndroidDevice() && Config.IS_BACK_FACING) 
   {
      (DeviceMotionEvent as any).requestPermission()
      .then((response: string) => {
        if (response == 'granted') {
          window.addEventListener('devicemotion', (e) => {
            // do something with e
            setPermissionGranted(true);
          })
        }
      })
      .catch(console.error)
   }
   else
   {
    setPermissionGranted(true);
   }
  }
  };

  React.useEffect(() => {

    
    if (snapLoaded){
        console.log("snap is loaded");
        setIsLoading(false);
    }

  }, [snapLoaded]);


  return (
    <div className="relative h-[100vh] w-full bg-black">
      {/* Show DesktopFallback if not on a mobile device */}

      {isMobile && !isPortrait && (
          <div className="fixed inset-0 bg-black/80">
            <LandscapeFallback instructions="Landscape mode is not supported, yet. " />
          </div>
        )}


      {!isMobile && (
        <DesktopFallback
          title={Config.TITLE}
          instructions={Config.INSTRUCTIONS_DESKTOP}
        />
      )}
  
      {/* Mobile device logic */}
      {isMobile && (
        <>
          {/* Show MobileIntro if in portrait mode and permission not granted yet */}
          {isPortrait && !permissionGranted && (
            <MobileIntro
              title={Config.TITLE}
              instructionsTitle={Config.INSTRUCTIONS_MOBILE_TITLE}
              instructions={Config.INSTRUCTIONS_MOBILE}
              requestPermission={requestPermission}
            />
          )}
  
          {/* Show MobileLoading when isLoading is true */}
          {isLoading && <MobileLoading instructions="LOADING..." />}
  
          {/* Load Snap component when in portrait mode, and optionally control its visibility or loading behavior based on isLoading or permissionGranted */}
          {isPortrait && permissionGranted && (
            <Snap onLoaded={() => {
              setSnapLoaded(true);
              setIsLoading(false); // Assuming you want to stop loading once Snap is loaded
            }} />
          )}
        </>
      )}
    </div>
  );
};

export default App;