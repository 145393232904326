// DesktopFallbackQRCode.tsx

import * as React from 'react';
import './styles.css';

const DesktopFallbackQRCode: React.FC = () => {
  return (
    <div className="w-max mx-auto overflow-clip rounded-lg">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="128px" height="128px" viewBox="0 0 1155 1155" enableBackground="new 0 0 1155 1155" xmlSpace="preserve">
<rect x="0" y="0" width="1155" height="1155" fill="rgb(255,255,255)" /><g transform="translate(70,70)"><g transform="translate(280,0) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(315,0) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,0) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(385,0) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,0) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,0) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,0) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(595,0) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,0) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,35) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,35) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(385,35) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,35) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,35) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(665,35) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,35) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,70) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(385,70) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,70) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,70) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,70) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,70) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(385,105) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,105) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(595,105) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,105) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,140) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(315,140) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,140) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,140) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(490,140) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,140) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,140) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(595,140) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,140) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(665,140) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(315,175) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,175) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,175) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,175) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,175) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,175) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,210) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,210) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,210) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(490,210) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,210) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,210) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,210) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,245) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,245) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(455,245) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,245) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,245) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,245) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(665,245) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(105,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(140,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(175,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(210,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(245,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(315,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(385,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(455,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(595,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(665,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(735,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(910,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(945,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(980,280) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(105,315) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(140,315) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(315,315) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(385,315) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,315) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(455,315) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,315) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(665,315) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(805,315) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,315) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(910,315) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(945,315) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(70,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(105,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(140,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(210,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(385,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(455,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(735,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(805,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(910,350) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(70,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(175,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(245,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(455,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(595,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(735,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(770,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(875,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(980,385) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(35,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(175,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(210,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(245,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(315,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(455,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(770,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(805,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(980,420) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(70,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(105,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(175,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(315,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(385,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(455,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(490,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(595,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(770,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(805,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(875,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(910,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(945,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(980,455) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(35,490) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(210,490) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,490) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(455,490) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,490) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,490) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(665,490) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(910,490) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(980,490) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(35,525) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(70,525) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(105,525) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(140,525) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,525) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,525) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,525) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(770,525) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(805,525) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,525) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(910,525) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(980,525) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(35,560) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(105,560) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(210,560) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(245,560) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(385,560) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,560) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(490,560) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(875,560) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(35,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(140,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(245,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(455,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(490,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(595,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(665,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(910,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(945,595) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(35,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(70,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(105,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(175,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(210,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(665,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(735,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(875,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(980,630) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,665) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(35,665) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(175,665) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(315,665) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,665) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(385,665) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(490,665) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,665) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(735,665) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(805,665) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,665) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(875,665) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(910,665) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(35,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(70,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(105,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(140,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(175,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(210,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(455,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(490,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(595,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(735,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(770,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(805,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(875,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(910,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(945,700) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,735) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,735) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(490,735) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,735) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,735) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,735) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,735) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(875,735) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,770) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,770) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(490,770) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,770) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(595,770) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,770) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(665,770) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,770) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(770,770) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,770) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(875,770) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,805) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,805) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(490,805) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,805) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(595,805) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,805) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,805) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(315,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(350,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(385,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(420,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(455,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(490,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(665,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(735,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(770,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(805,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(875,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(945,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(980,840) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,875) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(385,875) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(455,875) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,875) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(595,875) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,875) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,875) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(735,875) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(980,875) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(315,910) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(385,910) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(490,910) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,910) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,910) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(595,910) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(665,910) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(805,910) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,910) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(910,910) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(945,910) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(980,910) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(315,945) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(630,945) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(805,945) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(875,945) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(910,945) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(980,945) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(280,980) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(315,980) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(525,980) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(560,980) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(665,980) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(700,980) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(735,980) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(770,980) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(805,980) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(840,980) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(875,980) scale(0.07,0.07)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,0) scale(2.45, 2.45)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<g>
	<path style={{fill:"none"}} d="M65.859,15.008H34.141c-4.082,0-7.869,1.258-10.979,3.398c-2.419,1.665-4.428,3.864-5.848,6.421
		C15.838,27.484,15,30.526,15,33.758v32.471c0,10.344,8.586,18.76,19.145,18.76L50,84.992l15.855-0.004
		C76.414,84.988,85,76.572,85,66.229V33.758C85,23.419,76.414,15.008,65.859,15.008z"/>
	<path d="M65.859,0.008H34.141h0C18.683,0.008,5.587,10.221,1.4,24.18c-0.433,1.444-0.771,2.928-1.006,4.445
		C0.135,30.299,0,32.013,0,33.758v32.471c0,18.619,15.32,33.76,34.141,33.76L50,99.992l15.859-0.004
		c18.82,0,34.141-15.141,34.141-33.76V33.758C100,15.148,84.68,0.008,65.859,0.008z M85,66.229c0,10.344-8.586,18.76-19.145,18.76
		L50,84.992l-15.855-0.004C23.586,84.988,15,76.572,15,66.229V33.758c0-3.231,0.838-6.273,2.313-8.931
		c1.42-2.557,3.429-4.756,5.848-6.421c3.11-2.141,6.897-3.398,10.979-3.398h31.719C76.414,15.008,85,23.419,85,33.758V66.229z"/>
</g>
</g></g><g transform="translate(770,0) scale(2.45, 2.45)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<g>
	<path style={{fill:"none"}} d="M65.859,15.008H34.141c-4.082,0-7.869,1.258-10.979,3.398c-2.419,1.665-4.428,3.864-5.848,6.421
		C15.838,27.484,15,30.526,15,33.758v32.471c0,10.344,8.586,18.76,19.145,18.76L50,84.992l15.855-0.004
		C76.414,84.988,85,76.572,85,66.229V33.758C85,23.419,76.414,15.008,65.859,15.008z"/>
	<path d="M65.859,0.008H34.141h0C18.683,0.008,5.587,10.221,1.4,24.18c-0.433,1.444-0.771,2.928-1.006,4.445
		C0.135,30.299,0,32.013,0,33.758v32.471c0,18.619,15.32,33.76,34.141,33.76L50,99.992l15.859-0.004
		c18.82,0,34.141-15.141,34.141-33.76V33.758C100,15.148,84.68,0.008,65.859,0.008z M85,66.229c0,10.344-8.586,18.76-19.145,18.76
		L50,84.992l-15.855-0.004C23.586,84.988,15,76.572,15,66.229V33.758c0-3.231,0.838-6.273,2.313-8.931
		c1.42-2.557,3.429-4.756,5.848-6.421c3.11-2.141,6.897-3.398,10.979-3.398h31.719C76.414,15.008,85,23.419,85,33.758V66.229z"/>
</g>
</g></g><g transform="translate(0,770) scale(2.45, 2.45)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<g>
	<path style={{fill:"none"}} d="M65.859,15.008H34.141c-4.082,0-7.869,1.258-10.979,3.398c-2.419,1.665-4.428,3.864-5.848,6.421
		C15.838,27.484,15,30.526,15,33.758v32.471c0,10.344,8.586,18.76,19.145,18.76L50,84.992l15.855-0.004
		C76.414,84.988,85,76.572,85,66.229V33.758C85,23.419,76.414,15.008,65.859,15.008z"/>
	<path d="M65.859,0.008H34.141h0C18.683,0.008,5.587,10.221,1.4,24.18c-0.433,1.444-0.771,2.928-1.006,4.445
		C0.135,30.299,0,32.013,0,33.758v32.471c0,18.619,15.32,33.76,34.141,33.76L50,99.992l15.859-0.004
		c18.82,0,34.141-15.141,34.141-33.76V33.758C100,15.148,84.68,0.008,65.859,0.008z M85,66.229c0,10.344-8.586,18.76-19.145,18.76
		L50,84.992l-15.855-0.004C23.586,84.988,15,76.572,15,66.229V33.758c0-3.231,0.838-6.273,2.313-8.931
		c1.42-2.557,3.429-4.756,5.848-6.421c3.11-2.141,6.897-3.398,10.979-3.398h31.719C76.414,15.008,85,23.419,85,33.758V66.229z"/>
</g>
</g></g><g transform="translate(70,70) scale(1.05, 1.05)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<rect width="100" height="100"/>
</g></g><g transform="translate(840,70) scale(1.05, 1.05)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<rect width="100" height="100"/>
</g></g><g transform="translate(70,840) scale(1.05, 1.05)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<rect width="100" height="100"/>
</g></g></g></svg>
	</div>

  );
};

export default DesktopFallbackQRCode;
