// config.ts
export const TITLE = "Wear Shahmaran";
export const INSTRUCTIONS = "Wear Shahmaran";
export const INSTRUCTIONS_DESKTOP = "Scan the QR code with your phone's camera to wear Sevdaliza's iconic headpiece in augmented reality.";
export const INSTRUCTIONS_MOBILE_TITLE = "Launch camera and allow permissions to wear Sevdaliza's iconic headpiece in augmented reality.";
export const INSTRUCTIONS_MOBILE = "Launch camera and allow permissions to wear Sevdaliza's iconic headpiece in augmented reality.";
export const LENS_ID = "94f7feee-a05d-4cd2-b8d6-d61ddebad844"
export const LENS_GROUP_ID = "a91f2abd-ed64-4105-bcef-a523b816ecf8"
export const IS_BACK_FACING = false;
export const IS_SINGLE_CAMERA = false;
export const VIDEO_TITLE = "Wear Shahmaran";
export const MOBILE_POSTER_IMG = "sevdaliza-shahmaran";
export const IS_VIDEO_AVAILABLE = true;
export const SENTRY_DSN = 'https://2deb15bd7e10eb5617c7673fe63c5bb0@o1021155.ingest.us.sentry.io/4507142206849024';

export const BG_VIDEO_SOURCE = {
    desktop: {
      1080: 'https://player.vimeo.com/progressive_redirect/playback/936646295/rendition/1080p/file.mp4?loc=external&log_user=0&signature=482721fe11065752f2e3d17f5991d2a01c23952ee30ea5472e95a2840acafad3',
      720: 'https://player.vimeo.com/progressive_redirect/playback/936646295/rendition/720p/file.mp4?loc=external&log_user=0&signature=7b4342762dd51b732fb9f2d7f1d6360f3de0f5853ba779449874bf1655d1b727',
      540: 'https://player.vimeo.com/progressive_redirect/playback/936646295/rendition/540p/file.mp4?loc=external&log_user=0&signature=dfbd1fa42e24cda26f36204d88d1fe23172cae522e329f0217a177f14d99e82b'
    },
    mobile: {
      1080: 'https://player.vimeo.com/progressive_redirect/playback/936646346/rendition/1080p/file.mp4?loc=external&log_user=0&signature=a7775f63a8e2e962851e41850a768efdf085bbceead0b6c33a73f4a5c37efbab',
      720: 'https://player.vimeo.com/progressive_redirect/playback/936646346/rendition/720p/file.mp4?loc=external&log_user=0&signature=8f6869eddb2a1220ff1de1ba584a77368c799db91481ac25201ae124f4d0c795',
      540: 'https://player.vimeo.com/progressive_redirect/playback/936646346/rendition/540p/file.mp4?loc=external&log_user=0&signature=3a2b09e34260114431ef3a08d44037309dae16b7ee3319b1f7af4f5b3f97d11b',
      360: 'https://player.vimeo.com/progressive_redirect/playback/936646346/rendition/360p/file.mp4?loc=external&log_user=0&signature=fc2b7f6e0cfe8d96f031a93a20b2dcb2456823f183ec48883f56771901e14dc4'
    }
  };