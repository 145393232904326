import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import * as Config from '../Config';

export function useSentry() {
  const sentryInitialized = useRef(false);
  useEffect(() => {
    if (sentryInitialized.current === false) {
      Sentry.init({
        dsn: Config.SENTRY_DSN,
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost']
          })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0 // Capture 100% of the transactions
      });
      sentryInitialized.current = true;
    }
  }, []);
}
